


































import BlogFilter from 'tradingmate-modules/src/filters/BlogFilter'
import { BlogModel, BlogPostStatus } from 'tradingmate-modules/src/models/api/blogs'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import { HttpError, Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import BlogCard from '@/components/collections/blogs/BlogCard.vue'
import Paging from '@/components/actions/Paging.vue'
import BlogType from '../../../tradingmate-modules/src/models/api/blogs/BlogType'

@Component({
  components: {
    BlogCard,
    Paging
  }
})
export default class ToolboxTalk extends Vue {
  private pagedBlogs: PagedResultSet<BlogModel> | null = null
  private filter = new BlogFilter({
    Status: BlogPostStatus.Published,
    BlogType: BlogType.None
  })

  mounted (): void {
    this.updateFilter()
    this.getBlogs()
  }

  updateFilter (): void {
    this.filter.Page = this.$route.query.page ? parseInt(this.$route.query.page.toString()) : 1
  }

  navToPage (nextPage: number): void {
    this.$router.push({ query: { page: nextPage?.toString() } })
    this.updateFilter()
    this.getBlogs()
  }

  private loading = false
  private errors: HttpError | null = null;
  getBlogs (): void {
    this.loading = true
    Services.API.Blogs.GetBlogsForPublic(this.filter)
      .then(returnData => {
        this.pagedBlogs = returnData
      }).catch((errors) => {
        this.errors = errors
      }).finally(() => {
        this.loading = false
      })
  }
}
